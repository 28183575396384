<template>
    <b-card class="row-height">
        <b-row class="col-md-12">
            <b-col>
                <h3 class="font-weight-bolder">
                    <b-link class="btn btn-outline-primary" to="/invoices">
                        <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
                        {{ $t("back") }}
                    </b-link>
                    <p style="margin-top: 15px" v-if="taskStatus == 0">
                        {{ $t("start_selling_your_initial_invoices") }}
                    </p>
                </h3>
            </b-col>
        </b-row>

        <b-col md="12" class="mt-2">
            <label class="font-weight-bolder">{{ $t("issuer") }}</label>
            <b-form-input
                class="col-md-5"
                :value="myComp ? myComp.name : '/'"
                disabled
            ></b-form-input>
        </b-col>
        <b-col md="12" class="mt-2">
            <b-form-file
                style="display: none"
                @change="uploadFile($event, file1)"
                v-model.trim="file1"
                multiple
                :placeholder="$t('upload_a_file_or_drag_files_here')"
                drop-placeholder="Drop file here..."
                ref="fileInput1"
            ></b-form-file>

            <div class="mt-1 mr-0 pr-0">
                <b-alert show variant="info" class="col-md-6">
                    <b-row class="col-md-12">
                        <b-col
                            class="col-md-12 mt-2 mb-2 text-center pointer"
                            @click="triggerFileInput"
                        >
                            <div>
                                <feather-icon icon="DownloadIcon" size="25"/>
                                <span></span>
                                {{ $t("upload_a_file_or_drag_files_here") }}
                            </div>
                        </b-col>
                    </b-row>
                </b-alert>
            </div>
        </b-col>

        <b-row md="12">
            <validation-observer
                class="col-md-12"
                ref="uploadInvoices"
                v-model.trim="valid"
            >
                <b-col v-for="(item, index) in uploadedInvoices" md="12" :key="index">
                    <div class="border-blue-left mt-3 mb-1 ml-1">
                        <h4 class="pl-1 col-md-12">
              <span class="light-blue"
              >{{ $t("Invoice") }} {{ index + 1 }} :</span
              >
                            {{ item ? item.title : "" }}
                        </h4>
                    </div>

                    <b-form>
                        <div class="col-md-12">
                            <b-row m-0 p-0 v-if="!load">
                                <b-col sm="12" xl="12" md="12" lg="12" class="mt-2">
                                    <!-- <b-form-group class="d-flex" v-slot="{}">
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="$t('recourse')"
                                        rules="required"
                                      >
                                        <b-form-radio-group
                                          v-model="item.recourse"
                                          :options="recourseOptions"
                                          class="mb-1"
                                          :disabled="item.status != 0"
                                          value-field="value"
                                          text-field="name"
                                        >
                                        </b-form-radio-group>
                                      </validation-provider>
                                    </b-form-group> -->
                                    <b-form-group v-slot="{ ariaDescribedby }" class="d-flex" :id="$t('recourse') + ' ' + index">
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('recourse')"
                                            rules="required"
                                        >
                                            <div class="d-flex">
                                                <b-form-radio @change="changeRecourse(item,index, 0)" v-b-tooltip.hover :title="$t(recourseOptions[0].title)" :id="'non-recourse' + index" v-model="item.recourse" :aria-describedby="ariaDescribedby"
                                                              name="Non-recourse" value="0" class="mr-2">
                                                    {{ $t('Non-recourse') }}
                                                </b-form-radio>
                                                <b-form-radio @change="changeRecourse(item,index, 1)" v-b-tooltip.hover v-model="item.recourse" :title="$t(recourseOptions[1].title)" :aria-describedby="ariaDescribedby" name="Recourse" value="1"
                                                              class="mr-2" :id="'recourse' + index"> {{ $t('Recourse')
                                                    }}
                                                    <small>{{ $t('recourse_text') }}</small>
                                                </b-form-radio>
                                                <b-form-radio @change="changeRecourse(item,index, 2)" v-b-tooltip.hover v-model="item.recourse" :title="$t(recourseOptions[2].title)" :aria-describedby="ariaDescribedby" name="Reverse" value="2"
                                                              class="mr-2" :id="'reverse' + index"> {{ $t('Reverse') }}
                                                </b-form-radio>
                                            </div>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" xl="8" md="8" lg="8">
                                    <b-form-group
                                        id="deptor"
                                        :label="(item.recourse == 2 ? $t('supplier') : $t('deptor')) + ':'"
                                        label-for="deptor"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="(item.recourse == 2 ? $t('supplier') : $t('deptor')) + ' ' + index"
                                            rules="required"
                                        >
                                            <!--:rules = getValidationRules(item)-->
                                            <vue-autosuggest
                                                :suggestions="filteredOptions"
                                                :limit="10"
                                                v-model.trim="item.company_name"
                                                :input-props="{
                          id: 'autosuggest__input',
                          class: 'form-control',
                        }"
                                                @selected="onSelected($event, item, index)"
                                                @keyup="searchForCompaniesName(item)"
                                            >
                                                <template slot-scope="{ suggestion }">
                          <span class="my-suggestion-item"
                          >{{ suggestion.item }}
                          </span>
                                                </template>
                                            </vue-autosuggest>
                                            <b-button
                                                variant="primary"
                                                v-if="showHint"
                                                class="mt-1"
                                                disabled
                                            >
                                                <b-spinner small type="grow"></b-spinner>
                                                {{ $t("loading") }}...
                                            </b-button>
                                            <div class="alert alert-secondary p-1 mt-1" v-if="companyMessage">
                                                <span v-if="$store.state.app.language == 'en'"> If you can not find the compnay of your {{ (item.recourse == 2 ? 'supplier' : 'debtor') }} on the list please contact administrator at  <strong>support@aequitex.com</strong> or you can <a @click="openLink()" style="text-decoration:underline;" class="font-weight-bolder">book a meeting here!</a></span>

                                                <span v-else-if="$store.state.app.language == 'de'">
                      Falls Sie die Firma Ihres {{ (item.recourse == 2 ? 'Anbieter' : 'Schuldners') }} in dieser Liste nicht finden können, kontaktieren Sie bitte den Administrator über <strong>support@aequitex.com</strong>  oder <a @click="openLink()" style="text-decoration:underline;" class="font-weight-bolder">buchen Sie sich hier Ihr persönliches Video-Meeting</a>.
                     </span>

                                            </div>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <img
                                        v-if="loader"
                                        width="50"
                                        height="50"
                                        src="/new-loader.svg"
                                    />
                                </b-col>

                                <b-col sm="12" xl="4" md="4" lg="4">
                                    <b-form-group
                                        id="currency"
                                        class="font-weight-bolder"
                                        :label="$t('currency') + ':'"
                                        label-for="currency"
                                        clasa="d-flex flex-direction-column"
                                    >
                                        <b-dropdown id="currency-dropdown" variant="outline-dark" class="w-50 no-arrow">

                                            <template #button-content>
                                                <span v-if="currency_name">{{ currency_name }}</span>
                                                <span v-else>{{ $t('select_currency') }}</span>
                                            </template>

                                            <b-dropdown-item
                                                v-for="option in currencies"
                                                :key="option.id"
                                                :value="option.id"
                                                @click="changeCurrency(option.id, option.name)"
                                            >
                                                {{ option.name }}
                                            </b-dropdown-item>

                                        </b-dropdown>

                                        <div>
                                            <small class="text-danger" v-if="currencyError">{{ $t(currencyError) }}
                                            </small>
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col sm="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="amount"
                                        class="font-weight-bolder"
                                        :label="$t('invoice_amount') + ':'"
                                        label-for="amount"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('invoice_amount') + ' ' + index"
                                            rules="required"
                                        >
                                            <input
                                                @focusout="parsedAmount1($event, item.amount, index)"
                                                :id="$t('invoice_amount') + ' ' + index"
                                                v-model.trim="item.amount"
                                                placeholder="00'000.00"
                                                class="form-control"
                                            />

                                            <small class="text-danger" v-if="numbersAmountError">{{
                                                numbersAmountError
                                                }}
                                            </small>
                                            <br v-if="numbersAmountError"/>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="issue_date"
                                        :label="$t('issue_date') + ':'"
                                        label-for="issue_date"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('issue_date') + ' ' + index"
                                            rules="required"
                                        >
                                            <b-form-datepicker
                                                :max="disabledDates"
                                                no-flip
                                                :id="$t('issue_date') + ' ' + index"
                                                @input="changeTerms(item, index)"
                                                v-model.trim="item.issue_date"
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                                required
                                                :placeholder="$t('choose_a_date')"
                                                :locale="
                          $store.state.app && $store.state.app.language == 'de'
                            ? 'de'
                            : 'en'
                        "
                                            ></b-form-datepicker>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="payment_date"
                                        :label="$t('payment_term_in_days') + ':'"
                                        label-for="payment_date"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('payment_term_in_days') + ' ' + index + ' (' + $t('calculated') + ') :'"
                                            rules="required"
                                        >
                                            <b-form-input
                                                :id="'payment_term_in_days_' + index"
                                                v-model.trim="item.terms"
                                                type="number"
                                                max="90"
                                                disabled
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            <small
                                                class="text-danger"
                                                v-if="errorDueDate.length > 0"
                                            >{{ errorDueDate }}
                                            </small
                                            >
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="due_date"
                                        class="font-weight-bolder"
                                        :label="$t('due_date') + ':'"
                                        label-for="due_date"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="due_date"
                                            rules="required"
                                        >
                                            <b-form-datepicker
                                                no-flip
                                                :id="'due_date' + index"
                                                v-model.trim="item.due_date"
                                                :disabled="disableInputs"
                                                @input="changeTerms(item, index)"
                                                required
                                                :placeholder="$t('choose_a_date')"
                                            ></b-form-datepicker>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        class="font-weight-bolder"
                                        :label="$t('invoice_number') + ':'"
                                        label-for="invoice_number"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('invoice_number') + ' ' + index"
                                            rules="required"
                                        >
                                            <b-form-input
                                                :id="$t('invoice_number') + ' ' + index"
                                                v-model.trim="item.invoice_number"
                                                :disabled="disableInputs"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!--<b-col sm="12" v-if="item.is_reverse == 1">-->
                                    <!--<b-row>-->
                                        <!--<b-col lg="6" md="6" sm="12" xl="6">-->
                                            <!--<b-form-group :label="$t('bic') + ':'" class="font-weight-bolder" label-for="bic">-->
                                                <!--<validation-provider #default="{ errors }" :name="$t('bic')" rules="required">-->
                                                    <!--<b-form-input id="bic" v-model.trim="item.bic"></b-form-input>-->
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <!--</validation-provider>-->
                                            <!--</b-form-group>-->
                                        <!--</b-col>-->
                                        <!--<b-col lg="6" md="6" sm="12" xl="6">-->
                                            <!--<b-form-group :label="$t('iban') + ':'" class="font-weight-bolder" label-for="iban">-->
                                                <!--<validation-provider #default="{ errors }" :name="$t('iban')" rules="required">-->
                                                    <!--<b-form-input id="iban" v-model.trim="item.iban"></b-form-input>-->
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <!--</validation-provider>-->
                                            <!--</b-form-group>-->
                                        <!--</b-col>-->
                                        <!--<b-col lg="6" md="6" sm="12" xl="6">-->
                                            <!--<b-form-group :label="$t('bank_address') + ':'" class="font-weight-bolder" label-for="bank_address">-->
                                                <!--<validation-provider #default="{ errors }" :name="$t('bank_address')" rules="required">-->
                                                    <!--<b-form-input id="bank_address" v-model.trim="item.bank_address"></b-form-input>-->
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <!--</validation-provider>-->
                                            <!--</b-form-group>-->
                                        <!--</b-col>-->
                                        <!--<b-col lg="6" md="6" sm="12" xl="6">-->
                                            <!--<b-form-group :label="$t('bank_name') + ':'" class="font-weight-bolder" label-for="bank_name">-->
                                                <!--<validation-provider #default="{ errors }" :name="$t('bank_name')" rules="required">-->
                                                    <!--<b-form-input id="bank_name" v-model.trim="item.bank_name"></b-form-input>-->
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <!--</validation-provider>-->
                                            <!--</b-form-group>-->
                                        <!--</b-col>-->
                                    <!--</b-row>-->
                                <!--</b-col>-->

                                <!-- <b-col cols="6" xl="6" md="6" lg="6" align="end">
                                  <div class="mt-2">
                                    <b-button
                                      variant="primary"
                                      @click="removeItem(item, index)"
                                      >{{ $t("delete") }}</b-button
                                    >
                                  </div>
                                </b-col> -->
                                <b-col sm="12" xl="6" md="6" lg="6" class="mt-2"></b-col>

                                <!-- <div class="col-12  pt-0" v-if="item.recourse == 1">
                                    <b-form-group>
                                        <b-form-checkbox v-model.trim="silent_factoring_accepted"
                                          readonly disabled >
                                          <span  v-b-tooltip.hover :title="$t('silent_factoring_tooltip')">
                                            {{ $t("silent_factoring") }}
                                          </span>
                                        </b-form-checkbox>
                                    </b-form-group>
                                  </div> -->
                                <b-col
                                    sm="12"
                                    xl="12"
                                    md="12"
                                    lg="12"
                                    class="mt-0"
                                    v-if="item.recourse == 1 || item.recourse == 2"
                                >
                                    <div class="col-12 alert alert-secondary p-2">

                                        <template v-if="item.recourse == 2">
                                            <div v-if="$store.state.app.language === 'en'">
                                                I hereby confirm the following agreement within the framework of reverse
                                                factoring:
                                                <br>
                                                <br>
                                                1.1 The contractual partner (debtor) transfers its liabilities from
                                                deliveries and services to the invoice purchaser (factor). This invoice
                                                purchaser (factor) shall assume these liabilities on account of
                                                fulfilment subject to clauses 1.2 and 1.3 of this agreement.
                                                <br>
                                                <br>
                                                1.2 This is a case of reverse factoring. This means that the contractual
                                                partner (debtor) retains the default risk for the transferred
                                                liabilities.
                                                <br>
                                                <br>
                                                1.3 The contractual partner (debtor) pays the amount of its liability to
                                                Aequitex AG. The contractual partner (debtor) transfers the full amount
                                                of his liabilities to Aequitex AG on the due date stated on the invoice
                                                (credit note required).
                                                <br>
                                                <br>
                                                1.4 The contractual partner (debtor) assumes all costs arising from his
                                                payment default, including reminder and collection costs.
                                            </div>
                                            <div v-else-if="$store.state.app.language === 'de'">
                                                Hiermit bestätige ich folgende Vereinbarung im Rahmen des
                                                Reverse-Factorings:
                                                <br>
                                                <br>
                                                1.1 Der Vertragspartner (Debtor) übergibt seine Verbindlichkeiten aus
                                                Lieferungen und Leistungen an den Rechnungskäufer (Factor) ab. Dieser
                                                Rechnungskäufer (Factor) übernimmt diese Verbindlichkeiten
                                                erfüllungshalber vorbehaltlich Ziff. 1.2 und 1.3 dieses Vertrages.
                                                <br>
                                                <br>
                                                1.2 Es handelt sich um ein Reverse Factoring. Das bedeutet, dass der
                                                Vertragspartner (Debtor) das Ausfallrisiko für die übergebenen
                                                Verbindlichkeiten behält.
                                                <br>
                                                <br>
                                                1.3 Der Vertragspartner (Debtor) begleicht den Betrag seiner
                                                Verbindlichkeit an die Aequitex AG. Der Vertragspartner (Debtor)
                                                überweist Aequitex AG den vollen Betrag seiner Verbindlichkeiten zum auf
                                                der Rechnung angegebenen Fälligkeitsdatum (Gutschrift erforderlich).
                                                <br>
                                                <br>
                                                1.4 Der Vertragspartner (Debtor) übernimmt alle Kosten, die durch seinen
                                                Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div v-if="$store.state.app.language == 'en'">
                                                I hereby confirm the following agreement within the framework of
                                                recourse factoring:
                                                <br>
                                                <br>
                                                1.1 The Seller transfers its receivables from deliveries and services to
                                                the Buyer. The Buyer assumes these receivables on account of fulfilment
                                                subject to Clause 1.2 and § 3 of this agreement.
                                                <br>
                                                <br>
                                                1.2 This is a case of recourse factoring. This means that the seller
                                                assumes the default risk for the assigned receivables.
                                                <br>
                                                <br>
                                                1.3 The debtor pays the amount of the claim to the seller. The seller
                                                then forwards the claim amount received from the debtor to the buyer
                                                within 3 days of receipt.
                                                <br>
                                                <br>
                                                1.4 If the debtor has not paid the amount of the claim to the seller by
                                                the due date, the seller shall pay the amount of the claim to the buyer
                                                no later than the third day after the due date (credit note required).
                                                <br>
                                                <br>
                                                1.5 The Seller shall bear all costs incurred as a result of non-payment,
                                                including reminder and collection costs.
                                            </div>
                                            <div v-else-if="$store.state.app.language == 'de'">
                                                Hiermit bestätige ich folgende Vereinbarung im Rahmen des
                                                Recourse-Factoring:
                                                <br>
                                                <br>
                                                1.1 Der Verkäufer überträgt seine Forderungen aus Lieferungen und
                                                Leistungen an den Käufer. Der Käufer übernimmt diese Forderungen
                                                erfüllungshalber vorbehaltlich Ziff. 1.2 und § 3 dieses Vertrages.
                                                <br>
                                                <br>
                                                1.2 Es handelt sich um ein Recourse Factoring. Das bedeutet, dass der
                                                Verkäufer das Ausfallrisiko für die abgetretenen Forderungen übernimmt.
                                                <br>
                                                <br>
                                                1.3 Der Schuldner begleicht den Forderungsbetrag an den Verkäufer. Der
                                                Verkäufer leitet dann, den durch den Schuldner erhaltenen
                                                Forderungsbetrag, innerhalb von 3 Tagen nach Erhalt an den Käufer
                                                weiter.
                                                <br>
                                                <br>
                                                1.4 Falls der Schuldner den Forderungsbetrag bis zum Fälligkeitsdatum
                                                nicht an den Verkäufer beglichen haben sollte, begleicht der Verkäufer
                                                den Forderungsbetrag gegenüber dem Käufer spätestens am dritten Tag nach
                                                dem Fälligkeitsdatum (Gutschrift erforderlich).
                                                <br>
                                                <br>
                                                1.5 Der Verkäufer übernimmt alle Kosten, die durch einen Zahlungsausfall
                                                entstehen, einschließlich Mahn- und Inkassokosten.
                                            </div>
                                        </template>
                                        <b-row>
                                            <!--<b-col md="4" class="mt-2">-->
                                            <!--<div class="pb-0 font-weight-bolder">{{ $t("business_analysis") }}</div>-->
                                            <!--<validation-provider-->
                                            <!--#default="{ errors }"-->
                                            <!--:name="$t('business_analysis') + index"-->
                                            <!--rules="required"-->
                                            <!--v-if="!user_uploaded_analysis.business_analysis"-->
                                            <!--&gt;-->
                                            <!--<b-form-file-->
                                            <!--style="display: none"-->
                                            <!--@change="uploadRecourseFile($event, 'business_analysis',index)"-->
                                            <!--v-model.trim="item.business_analysis"-->
                                            <!--:placeholder="$t('upload_a_file_or_drag_files_here')"-->
                                            <!--drop-placeholder="Drop file here..."-->
                                            <!--:ref="'fileInput1' + index"-->
                                            <!--:id="'fileInput1' + index"-->
                                            <!--&gt;</b-form-file>-->
                                            <!--&lt;!&ndash;<small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
                                            <!--<div class="col-12 pl-0 pt-2 text-danger" v-if="filesMissing">{{ $t('required_field') }}</div>-->
                                            <!--</validation-provider>-->
                                            <!--<div v-else>-->
                                            <!--<b-form-file-->
                                            <!--style="display: none"-->
                                            <!--@change="uploadRecourseFile($event, 'business_analysis',index)"-->
                                            <!--v-model.trim="item.business_analysis"-->
                                            <!--:placeholder="$t('upload_a_file_or_drag_files_here')"-->
                                            <!--drop-placeholder="Drop file here..."-->
                                            <!--:ref="'fileInput1' + index"-->
                                            <!--:id="'fileInput1' + index"-->
                                            <!--&gt;</b-form-file>-->
                                            <!--<span>{{$t('you_already_uploaded_this_type_of_file')}}</span>-->
                                            <!--</div>-->
                                            <!--<div-->
                                            <!--class="mt-1 mr-0 pr-0"-->
                                            <!--v-if="!item.business_analysis"-->
                                            <!--&gt;-->
                                            <!--<b-alert show variant="info" class="col-md-8">-->
                                            <!--<b-row class="col-md-12">-->
                                            <!--<b-col-->
                                            <!--class="col-md-12 mt-2 mb-2 text-center pointer"-->
                                            <!--@click="triggerFileInput1(index)"-->
                                            <!--&gt;-->
                                            <!--<div>-->
                                            <!--<feather-icon icon="DownloadIcon" size="25" />-->
                                            <!--<span></span>-->
                                            <!--{{ $t("upload_a_file_or_drag_files_here") }}-->
                                            <!--</div>-->
                                            <!--</b-col>-->
                                            <!--</b-row>-->
                                            <!--</b-alert>-->
                                            <!--</div>-->

                                            <!--<div v-if="item.business_analysis" class="mt-1 mr-0 pr-0">-->
                                            <!--<b-alert show variant="success" class="col-md-8">-->
                                            <!--<b-row class="col-md-12">-->
                                            <!--<b-col class="col-md-12 mt-2 mb-2 text-center">-->
                                            <!--<div>-->
                                            <!--<feather-icon-->
                                            <!--icon="CheckIcon"-->
                                            <!--size="25"-->
                                            <!--&gt;</feather-icon>-->
                                            <!--<span>-->
                                            <!--{{ $t("uploaded") }}-->
                                            <!--</span>-->
                                            <!--</div>-->
                                            <!--</b-col>-->
                                            <!--</b-row>-->
                                            <!--</b-alert>-->
                                            <!--</div>-->
                                            <!--</b-col>-->
                                            <b-col md="6" class="mt-2">
                                                <div class="pb-0 font-weight-bolder">{{ $t("annual_balance") }}</div>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="$t('annual_balance') + index"
                                                    rules="required"
                                                    v-if="!user_uploaded_analysis.annual_balance"
                                                >
                                                    <b-form-file
                                                        style="display: none"
                                                        @change="uploadRecourseFile($event, 'annual_balance',index)"
                                                        v-model.trim="item.annual_balance"
                                                        :placeholder="$t('upload_a_file_or_drag_files_here')"
                                                        drop-placeholder="Drop file here..."
                                                        :ref="'fileInput2' + index"
                                                        :id="'fileInput2' + index"
                                                    ></b-form-file>
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                    <div class="col-12 pl-0 pt-2 text-danger" v-if="filesMissing">{{
                                                        $t('required_field') }}
                                                    </div>
                                                </validation-provider>
                                                <div v-else>
                                                    <b-form-file
                                                        style="display: none"
                                                        @change="uploadRecourseFile($event, 'annual_balance',index)"
                                                        v-model.trim="item.annual_balance"
                                                        :placeholder="$t('upload_a_file_or_drag_files_here')"
                                                        drop-placeholder="Drop file here..."
                                                        :ref="'fileInput2' + index"
                                                        :id="'fileInput2' + index"
                                                    ></b-form-file>
                                                    <span>{{$t('you_already_uploaded_this_type_of_file')}}</span>
                                                </div>
                                                <div class="mt-1 mr-0 pr-0" v-if="!item.annual_balance">
                                                    <b-alert show variant="info" class="col-md-8">
                                                        <b-row class="col-md-12">
                                                            <b-col
                                                                class="col-md-12 mt-2 mb-2 text-center pointer"
                                                                @click="triggerFileInput2(index)"
                                                            >
                                                                <div>
                                                                    <feather-icon icon="DownloadIcon" size="25"/>
                                                                    <span></span>
                                                                    {{ $t("upload_a_file_or_drag_files_here") }}
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                    </b-alert>
                                                </div>
                                                <div v-if="item.annual_balance" class="mt-1 mr-0 pr-0">
                                                    <b-alert show variant="success" class="col-md-8">
                                                        <b-row class="col-md-12">
                                                            <b-col class="col-md-12 mt-2 mb-2 text-center">
                                                                <div>
                                                                    <feather-icon
                                                                        icon="CheckIcon"
                                                                        size="25"
                                                                    ></feather-icon>
                                                                    <span>
                                  {{ $t("uploaded") }}
                                </span>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                    </b-alert>
                                                </div>
                                            </b-col>

                                            <b-col md="6" class="mt-2">
                                                <div class="pb-0 font-weight-bolder">{{ $t("tax_settlement") }}</div>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="$t('tax_settlement') + index"
                                                    rules="required"
                                                    v-if="!user_uploaded_analysis.tax_settlement"
                                                >
                                                    <b-form-file
                                                        style="display: none"
                                                        @change="uploadRecourseFile($event, 'tax_settlement',index)"
                                                        v-model.trim="item.tax_settlement"
                                                        :placeholder="$t('upload_a_file_or_drag_files_here')"
                                                        drop-placeholder="Drop file here..."
                                                        :ref="'fileInput3' + index"
                                                        :id="'fileInput3' + index"
                                                    ></b-form-file>
                                                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                    <div class="col-12 pl-0 pt-2 text-danger" v-if="filesMissing">{{
                                                        $t('required_field') }}
                                                    </div>
                                                </validation-provider>
                                                <div v-else>
                                                    <b-form-file
                                                        style="display: none"
                                                        @change="uploadRecourseFile($event, 'tax_settlement',index)"
                                                        v-model.trim="item.tax_settlement"
                                                        :placeholder="$t('upload_a_file_or_drag_files_here')"
                                                        drop-placeholder="Drop file here..."
                                                        :ref="'fileInput3' + index"
                                                        :id="'fileInput3' + index"
                                                    ></b-form-file>
                                                    <span>{{$t('you_already_uploaded_this_type_of_file')}}</span>
                                                </div>
                                                <div class="mt-1 mr-0 pr-0" v-if="!item.tax_settlement">
                                                    <b-alert show variant="info" class="col-md-8">
                                                        <b-row class="col-md-12">
                                                            <b-col
                                                                class="col-md-12 mt-2 mb-2 text-center pointer"
                                                                @click="triggerFileInput3(index)"
                                                            >
                                                                <div>
                                                                    <feather-icon icon="DownloadIcon" size="25"/>
                                                                    <span></span>
                                                                    {{ $t("upload_a_file_or_drag_files_here") }}
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                    </b-alert>
                                                </div>
                                                <div v-if="item.tax_settlement" class="mt-1 mr-0 pr-0">
                                                    <b-alert show variant="success" class="col-md-8">
                                                        <b-row class="col-md-12">
                                                            <b-col class="col-md-12 mt-2 mb-2 text-center">
                                                                <div>
                                                                    <feather-icon
                                                                        icon="CheckIcon"
                                                                        size="25"
                                                                    ></feather-icon>
                                                                    <span>
                                  {{ $t("uploaded") }}
                                </span>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                    </b-alert>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <div class="col-12 pl-0 pt-2 text-danger" v-if="filesMissing">{{
                                            $t('upload_files') }}
                                        </div>
                                        <div class="col-12 pl-0 pt-2">


                                            <b-form-group v-if="item.recourse == 1">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    rules="required"
                                                    :name="'accept recourse '"
                                                    :id="'accept_recourse' + '' + index"
                                                >
                                                    <b-form-checkbox
                                                        v-model.trim="item.accept_recourse"
                                                        @change="changeInvoiceFact(item,index)"
                                                        required
                                                        :state="errors.length > 0 ? false : null"
                                                        :name="'accept recourse ' + index"
                                                    >
                            <span>
                              {{ $t("accept") }}
                            </span>
                                                    </b-form-checkbox>
                                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                                </validation-provider>
                                            </b-form-group>


                                            <b-form-group v-else-if="item.recourse == 2">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    rules="required"
                                                    :name="'accept reverse '"
                                                    :id="'accept_reverse' + '' + index"
                                                >
                                                    <b-form-checkbox
                                                        v-model.trim="item.acceptReverse"
                                                        @change="acceptReverseChange"
                                                        :state="acceptReverseError ? false : null"
                                                        :disabled="item.status != 0"
                                                        :name="'accept reverse ' + index"
                                                        required
                                                    >
                          <span>
                            {{ $t("accept") }}
                          </span>
                                                    </b-form-checkbox>
                                                    <small class="text-danger" v-if="acceptReverseError">{{ $t("This
                                                        field is required") }}
                                                    </small>
                                                </validation-provider>
                                            </b-form-group>


                                        </div>
                                    </div>
                                </b-col>

                                <!--<b-col-->
                                <!--sm="12"-->
                                <!--xl="12"-->
                                <!--md="12"-->
                                <!--lg="12"-->
                                <!--class="mt-0"-->
                                <!--v-if="item.recourse == 2"-->
                                <!--&gt;-->
                                <!--<div class="col-12 alert alert-secondary p-2">-->
                                <!--<div v-if="$store.state.app.language === 'en'">-->
                                <!--I hereby confirm the following agreement within the framework of reverse factoring:-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.1 The contractual partner (debtor) transfers its liabilities from deliveries and services to the invoice purchaser (factor). This invoice purchaser (factor) shall assume these liabilities on account of performance, subject to clauses 1.2 and 1.3 of this agreement.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.2 This is a case of reverse factoring. This means that the contractual partner (debtor) retains the default risk for the transferred liabilities.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.3 The contractual partner (debtor) pays the amount of its liability to Aequitex AG. The contractual partner (debtor) transfers the full amount of his liabilities to Aequitex AG on the due date stated on the invoice.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.4 The contractual partner (debtor) assumes all costs arising from his non-payment, including reminder and collection costs.-->
                                <!--</div>-->
                                <!--<div v-else-if="$store.state.app.language === 'de'">-->
                                <!--Hiermit bestätige ich folgende Vereinbarung im Rahmen des Reverse-Factorings:-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.1 Der Vertragspartner (Debtor) übergibt seine Verbindlichkeiten aus Lieferungen und Leistungen an den Rechnungskäufer (Factor) ab. Dieser Rechnungskäufer (Factor) übernimmt diese Verbindlichkeiten erfüllungshalber vorbehaltlich Ziff. 1.2 und 1.3 dieses Vertrages.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.2 Es handelt sich um ein Reverse Factoring. Das bedeutet, dass der Vertragspartner (Debtor) das Ausfallrisiko für die übergebenen Verbindlichkeiten behält.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.3 Der Vertragspartner (Debtor) begleicht den Betrag seiner Verbindlichkeit an die Aequitex AG. Der Vertragspartner (Debtor) überweist Aequitex AG den vollen Betrag seiner Verbindlichkeiten zum auf der Rechnung angegebenen Fälligkeitsdatum.-->
                                <!--<br>-->
                                <!--<br>-->
                                <!--1.4 Der Vertragspartner (Debtor) übernimmt alle Kosten, die durch seinen Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.-->
                                <!--</div>-->

                                <!--<div class="col-12 pl-0 pt-2">-->
                                <!--<b-form-group>-->
                                <!--<validation-provider-->
                                <!--#default="{ errors }"-->
                                <!--rules="required"-->
                                <!--&gt;-->
                                <!--<b-form-checkbox-->
                                <!--v-model.trim="item.acceptReverse"-->
                                <!--@change="acceptReverseChange"-->
                                <!--:state="acceptReverseError ? false : null"-->
                                <!--:disabled="item.status != 0"-->
                                <!--required-->
                                <!--&gt;-->
                                <!--<span>-->
                                <!--{{ $t("accept") }}-->
                                <!--</span>-->
                                <!--</b-form-checkbox>-->
                                <!--<small class="text-danger" v-if="acceptReverseError">{{ $t("This field is required") }}</small>-->
                                <!--</validation-provider>-->
                                <!--</b-form-group>-->
                                <!--</div>-->
                                <!--</div>-->
                                <!--</b-col>-->

                            </b-row>
                            <b-row v-else cols="12" xl="6" md="6" lg="6">
                                <img src="/new-loader.svg"/>
                            </b-row>
                        </div>
                    </b-form>
                    <hr/>
                </b-col>

                <b-row class="col-md-12" v-if="uploadedInvoices.length > 0" align="end">
                    <b-col class="no-padding">
                        <b-button variant="primary" @click="submit()">{{
                            $t("submit")
                            }}
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>

            <AddNewCompnayModal
                :showDialog="addNewComp"
                :invoice="invoice"
                :company="companyZefixData"
                @showSettings="showSettings"
                @closeAddCompModal="closeAddCompModal"
            ></AddNewCompnayModal>
            <MainContactModal
                :showDialog="MainContact"
                :invoice="invoice"
                :company="companyZefixDataMainContact"
                @showSettings="showSettings"
                @closeMainContactModal="closeMainContactModal"
            ></MainContactModal>
        </b-row>
    </b-card>
</template>
<script>
  import {email, required} from "@validations";
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import AddNewCompnayModal from "../cookpit/AddNewCompanyModal.vue";
  import {VueAutosuggest} from "vue-autosuggest";
  import Cleave from "vue-cleave-component";
  import {phone} from "phone";
  import MainContactModal from "./MainContactModal.vue";
  import i18n from "@/libs/i18n";

  export default {
    data() {
      return {
        silent_factoring_accepted: true,
        filesMissing: false,
        // disabledSumbit: true,
        openModal: false,
        companyZefixData: null,
        file1: null,
        selectedFile: null,
        eventFile: null,
        singleFile: null,
        showDetails: false,
        load: false,
        myComp: "",
        company_id: this.$store.state.auth.user.company_id,
        debtor: null,
        selected: "",
        payment_date: "",
        issue_date: "",
        due_date: "",
        recourse: 1,
        invoice_num: Number,
        filteredOptions: [],
        MainContact: false,
        companyZefixDataMainContact: null,
        maxDays: 90,
        amount: Number,
        acceptReverseError: true,
        acceptReverse: false,
        companies: [
          {
            text: "Please select an option",
            value: null,
          },
        ],
        disableInputs: false,
        addNew: false,
        uploadedInvoices: [],
        invoice: null,
        parsedDate: "",
        valid: false,
        addNewComp: false,
        selectedIndex: 0,
        successMessage: false,
        timeout: null,
        formatDateInput: "",
        recourseOptions: [
          {
            value: 0,
            name: i18n.t("Non-Recourse"),
            title: 'non_recourse_tooltip_title'
          },
          {
            value: 1,
            name: i18n.t("Recourse"),
            title: 'recourse_tooltip_title'
          },
          {
            value: 2,
            name: i18n.t("Reverse"),
          },
        ],
        options: {
          date: {
            date: true,
            delimiter: "/",
            datePattern: ["d", "m", "Y"],
          },
          customDelimiter: {
            delimiters: ["'", "."],
            blocks: [2, 3, 2],
            uppercase: true,
          },
        },
        phoneContries: "",
        showHint: false,
        phoneCountriesList: [],
        phoneValidation: "",
        errorDueDate: "",
        numbersAmountError: "",
        companyMessage: "",
        taskStatus: 1,
        loader: false,
        acceptRecourse: false,
        user_uploaded_analysis: [],
        currencies: null,
        currency_name: null,
        currency_id: null,
        currencyError: null,
      };
    },
    components: {
      ValidationProvider,
      ValidationObserver,

      AddNewCompnayModal,
      VueAutosuggest,
      Cleave,
      MainContactModal,
    },
    computed: {
      // disabledSumbit(){
      //   return (
      //     this.item.company_name &&
      //     this.item.amount &&
      //     this.item.issue_date &&
      //     this.item.terms &&
      //     this.item.due_date &&
      //     this.item.invoice_number &&
      //     ((this.item.recourse === 1 && !this.acceptRecourse) || this.item.recourse === 0)
      //   );
      // },
      disabledDates() {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return today;
      },
    },
    mounted() {
      this.getCompanies();
      this.getUserAnalysis();
      this.getStatistics();
      this.getTasks();
    },
    created() {
      this.getCurrencies();
    },
    methods: {
      getCurrencies() {
        this.$http.post("/currency").then((response) => {
          if (response.data.status == 200) {
            this.currencies = response.data.value;
          }
        });
      },

      changeCurrency(id, name) {

        if (id) {
          this.currency_id = id;
        }

        if (name) {
          this.currency_name = name;
        }

      },

      getValidationRules(item) {
        return item.recourse != 1 ? 'required' : ''
      },

      getUserAnalysis() {
        this.$http.post(`/user_analysis/${this.$store.state.auth.user.id}`).then((res) => {
          this.user_uploaded_analysis = res.data.value;
        });
      },
      changeRecourse(item, index, value) {
        item.recourse = value;
        const i = this.uploadedInvoices[index];
        i.recourse = value
      },
      acceptReverseChange(val) {
        if (val) {
          this.acceptReverseError = false;
        }
      },
      uploadRecourseFile(event, fileType, index) {
        const file = event.target.files[0];
        if (file) {
          if (fileType == 'annual_balance') {
            this.uploadedInvoices[index].annual_balance = file;
          }
          if (fileType == 'tax_settlement') {
            this.uploadedInvoices[index].tax_settlement = file;
          }
          // if (fileType == 'business_analysis') {
          //   this.uploadedInvoices[index].business_analysis = file;
          // }
        }
      },
      triggerFileInput1(index) {
        // this.$refs['fileInput1'+index].$refs.input.click();
        document.getElementById('fileInput1' + index).click();
      },
      triggerFileInput2(index) {
        // this.$refs['fileInput2'+index].$refs.input.click();
        document.getElementById('fileInput2' + index).click();
      },
      triggerFileInput3(index) {
        // this.$refs['fileInput3'+index].$refs.input.click();
        document.getElementById('fileInput3' + index).click();
      },
      changeInvoiceFact(value, index) {
        const item = this.uploadedInvoices[index];
        // if ((item.business_analysis || this.user_uploaded_analysis.business_analysis) && (item.annual_balance || this.user_uploaded_analysis.annual_balance) && (item.tax_settlement || this.user_uploaded_analysis.tax_settlement)) {
        if ((item.annual_balance || this.user_uploaded_analysis.annual_balance) && (item.tax_settlement || this.user_uploaded_analysis.tax_settlement)) {
          item.accept_recourse = true;
          this.filesMissing = false;
        } else {
          item.accept_recourse = '';
          this.filesMissing = true
          return;
        }
      },
      getTasks() {
        this.$http.post("/company/tasks").then((res) => {
          this.taskStatus = res.data.value[1].status;
        });
      },
      parsedAmount1($event, val, index) {
        this.numbersAmountError = "";
        let regex = /^[0-9']*(.)?[0-9]*$/;

        if (regex.test(val)) {
          setTimeout(() => {
            if (val) {
              if (val.includes("'") || val.includes("’")) {
                val = val.replace(/'/gi, "");
                val = val.replace(/’/gi, "");
              }
              val = Number(val)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&'");
            }
            document.getElementById(
                this.$t("invoice_amount") + " " + index
            ).value = val;

            this.uploadedInvoices[index].amount = val;

            // console.log(
            //   document.getElementById(this.$t("invoice_amount") + " " + index)
            // );
          }, 1500);
        } else {
          // this.numbersAmountError = this.$t("only_numbers_allowed");
          // $event.preventDefault();
          // return;
        }
      },
      parsedAmount(val, index) {
        setTimeout(() => {
          if (val) {
            if (val.includes("'")) {
              val = val.replace(/'/gi, "");
            }
            val = Number(val)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&'");
          }

          document.getElementById("Invoice Amount " + index).value = val;
        }, 1000);
      },
      async onSelected(val, item, index) {
        this.loader = true;
        item.company_name = val.item;
        await this.getCompanyDataFromZefix(item, index);
      },

      async getCompanyDataFromZefix(item, index) {
        if (typeof this.companies[item.company_name] !== "undefined") {
          try {
            const res = await this.$http.post("/get-company-data-from-zefix", {
              search: this.companies[item.company_name],
            });

            if (res) {
              item.company_uid = res.data.value.uid;
              let data = {
                search: item.company_uid,
                invoice_id: item.id,
              };
              this.addNewComp = false;
              this.MainContact = false;

              const res2 = await this.$http.post("/invoice/getCompany", data);
              if (res2) {
                this.loader = false;

                if (res2.data.value && res2.data.value.from === "Database") {
                  item.issuer_id = res2.data.value.company.id;
                } else if (
                    res2.data.value &&
                    res2.data.value.from === "ContactPerson"
                ) {
                  this.showMainContactModal(item, index, res2.data.value.company);
                } else {
                  this.showModal(item, index, res2.data.value.company);
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      },
      searchForCompaniesName(item) {
        clearTimeout(this.timeout);
        clearTimeout(this.timeout);
        this.companyMessage = "";
        const self = this;
        this.timeout = setTimeout(function () {
          if (item.company_name.length > 2) {
            self.showHint = true;
            self.companies = [];
            self.filteredOptions = [];
            self.$http
                .post("/get-companies-name-from-zefix", {
                  search: item.company_name,
                })
                .then((res) => {
                  let obj = res.data.value;
                  let myArray = [];
                  for (var prop in obj) {
                    if (obj[prop] != self.myComp.uid) {
                      myArray.push(prop);
                    }
                  }
                  self.successMessage = false;
                  self.showHint = false;
                  self.filteredOptions.push({
                    data: myArray,
                  });
                  self.companies = res.data.value;
                  if (res.data.value.length < 1) {
                    self.companyMessage = true;
                  }
                });
          } else {
            self.companies = [];
            self.showHint = false;
            self.filteredOptions = [];
          }
        }, 1000);
      },

      selectIssuerValue(item) {
        item = this.companies[0].issuer_id;
        return item;
      },
      getStatistics() {
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              if (res) {
                this.myComp = res.data.value;
              }
            });
      },
      showSettings() {
        this.addNewComp = false;
        this.openModal = false;
      },
      closeMainContactModal(data) {
        if (data != null) {
          this.uploadedInvoices[this.selectedIndex].issuer_id =
              data[0].company_id;
        } else {
          this.uploadedInvoices[this.selectedIndex].company_name = "";
        }

        this.$bvModal.hide("MainContact");
      },
      closeAddCompModal(data) {
        //this.getCompanies();
        if (data != null) {
          this.uploadedInvoices[this.selectedIndex].company_name = data[0].name;
          this.uploadedInvoices[this.selectedIndex].uid = data[0].uid;
          this.uploadedInvoices[this.selectedIndex].issuer_id = data[0].id;
        } else {
          this.uploadedInvoices[this.selectedIndex].company_name = "";
        }
        this.$bvModal.hide("AddCompany");
      },
      showModal(item, index, company) {
        this.addNewComp = true;
        this.MainContact = false;
        this.companyZefixData = company;
        this.invoice = item;
        this.selectedIndex = index;
        // this.$bvModal.show("AddCompany");
        this.$nextTick(() => {

          this.$bvModal.show("AddCompany");
        });

      },
      showMainContactModal(item, index, company) {
        this.addNewComp = false;
        this.MainContact = true;
        this.companyZefixDataMainContact = company;
        this.invoice = item;
        this.selectedIndex = index;

        this.$nextTick(() => {
          this.$bvModal.show("MainContact");
        });

      },

      uploadFile(event, file) {
        this.eventFile = event;
        let config = {headers: {"Content-Type": "multipart/form-data"}};
        let formData = new FormData();

        event.target.files.forEach((el, index) => {
          formData.append("file" + index, el);
        });
        formData.append("length", event.target.files.length);
        //   formData.append("files", event.target.files);
        this.$http.post(`/invoice/storeFiles`, formData, config).then((res) => {
          if (res.data.status == 200) {
            this.uploadedInvoices = res.data.value;
            this.uploadedInvoices = res.data.value.map((item) => {
              return {
                ...item,
                recourse: 0,
                amount: null,
                accept_recourse: false,
                tax_settlement: null,
                // business_analysis: null,
                annual_balance: null,
              };
            }).filter(Boolean);

          } else {
            let text = this.$t(res.data.message);
            this.$swal({
              position: "center",
              icon: "error",
              title: text,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: "btn btn-secondary",
              },
              buttonsStyling: false,
            });
          }
        });
        // this.addInvoice(event);
      },
      // async submit() {
      //   let isValid = true;
      //   this.uploadedInvoices.forEach((el) => {
      //     if (el.recourse === 1 && !el.accept_recourse) {
      //       el.accept_recourse = "";
      //       this.$refs.uploadInvoices.validate();
      //       this.isValid = false;
      //     } else {
      //       this.isValid = true;
      //     }
      //   });
      //   if (this.isValid == false) {
      //     return;
      //   } else {
      //     //nothing
      //   }
      //   const formValidation = await this.$refs.uploadInvoices.validate();
      //   if (isValid && formValidation && this.numbersAmountError.length < 1) {
      //     this.uploadedInvoices.forEach((el) => {
      //       el.amount = el.amount.replace(/'/g, "");
      //     });
      //     this.$http
      //       .post(`/invoice/storeMultipleData`, {
      //         invoices: this.uploadedInvoices,
      //       })
      //       .then((res) => {
      //         if (res) {
      //           this.showSuccessMessage();
      //         }
      //       });
      //   }
      // },
      async submit() {
        let isValid = true;
        this.currencyError = '';

        this.uploadedInvoices.forEach((el) => {
          if ((el.recourse == 1 && !el.accept_recourse) || (el.recourse == 2 && !el.acceptReverse)) {
            el.accept_recourse = "";
            this.$refs.uploadInvoices.validate();
            this.isValid = false;
          } else {
            this.isValid = true;
          }
        });

        if (this.isValid == false) {
          return;
        }

        const formValidation = await this.$refs.uploadInvoices.validate();
        if (this.errorDueDate.length > 1) {
          this.$refs.formValidate.validate();
          this.isValid = false;
          return "";
        }
        if (!this.currency_id) {
          this.currencyError = 'The Currency 0 field is required';
          return;
        }
        if (isValid && formValidation && this.numbersAmountError.length < 1 && this.currency_id) {
          // if (isValid && formValidation && this.numbersAmountError.length < 1) {
          const formData = new FormData();

          this.uploadedInvoices.forEach((el, index) => {
            formData.append(`invoices[${index}][id]`, el.id);
            formData.append(`invoices[${index}][recourse]`, el.recourse);
            formData.append(`invoices[${index}][accept_recourse]`, el.accept_recourse);
            formData.append(`invoices[${index}][amount]`, el.amount.replace(/'/g, ""));
            formData.append(`invoices[${index}][company_name]`, el.company_name);
            formData.append(`invoices[${index}][issue_date]`, el.issue_date);
            formData.append(`invoices[${index}][terms]`, el.terms);
            formData.append(`invoices[${index}][due_date]`, el.due_date);
            formData.append(`invoices[${index}][invoice_number]`, el.invoice_number);
            formData.append(`invoices[${index}][bic]`, el.bic || '');
            formData.append(`invoices[${index}][iban]`, el?.iban || '');
            formData.append(`invoices[${index}][bank_name]`, el.bank_name || '');
            formData.append(`invoices[${index}][bank_address]`, el.bank_address || '');
            // if (el.business_analysis) {
            //   formData.append(`invoices[${index}][business_analysis]`, el.business_analysis);
            // }

            if (el.annual_balance) {
              console.log(el.annual_balance);
              formData.append(`invoices[${index}][annual_balance]`, el.annual_balance);
            }

            if (el.tax_settlement) {
              formData.append(`invoices[${index}][tax_settlement]`, el.tax_settlement);
            }
            formData.append(`invoices[${index}][currency_id]`, this.currency_id);

          });

          let config = {headers: {"Content-Type": "multipart/form-data"}};

          this.$http
              .post(`/invoice/storeMultipleData`, formData, config)
              .then((res) => {
                if (res) {
                  if (res.data.status == 200) {
                    this.showSuccessMessage(res.data.value[0].id);
                  } else {
                    this.$swal({
                      position: "center",
                      icon: "error",
                      title: this.$t(res.data.message),
                      showConfirmButton: false,
                      timer: 1500,
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  }

                }
              });
        }
      },
      removeItem(item, index) {
        this.$http.post(`/invoice/delete/${item.id}`).then((res) => {
          if (res) {
            this.uploadedInvoices.splice(index, 1);
          }
        });
      },
      showSuccessMessage(invoice_id = null) {
        let text = this.$t("invoice_information_saved_successfully");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((res) => {
          const redirectUrl = invoice_id ? `/invoice/${invoice_id}` : '/invoices';
          this.$router.push(redirectUrl);
        });
      },
      getCompanies() {
        this.$http
            .post("/company/admin/list?")
            .then((response) => {
              if (response.data.status == 200) {
                this.addNewComp = false;
                this.companies = response.data.value.data;

                this.companies.forEach((el) => {
                  if (el.id > el.id + 1) {
                    this.selected = el;
                  }

                  if (el.uid == this.myComp.uid) {
                    el.disabled = true;
                  }
                });
              }
            })
            .catch((error) => {
            });
      },
      changeTerms(item, index) {
        if (item.issue_date && item.due_date) {
          let compareDate = "";
          const startDate = new Date(item.issue_date);
          const endDate = new Date(item.due_date);
          const differenceInTime = endDate.getTime() - startDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          let term = Math.abs(Math.round(differenceInDays));
          this.errorDueDate = "";
          this.disabledSumbit = false;
          let input = document.getElementById("payment_term_in_days_" + index);
          input.value = term;
          item.terms = term;
        }
      },
      changeDueDate(item, index) {
        this.errorDueDate = "";
        if (Number(item.terms) > 120) {
          this.errorDueDate = this.$t("max_allowed_days_is_120");
          return;
        }

        if (item.length > 1) {
          item = 90;
        }
        let input = document.getElementById("due_date" + index + "__value_");

        if (item.issue_date && item.terms) {
          let newDate = new Date(item.issue_date);
          let term = Number(item.terms);
          let parsed = new Date(item.issue_date);
          let compareDate = "";
          newDate.setDate(parsed.getDate() + term);
          compareDate = newDate;
          let lang = "en-us";
          if (this.$store.state.app.language == "de") {
            lang = "de-de";
          }
          input.innerText = newDate.toLocaleDateString(lang, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          item.due_date = newDate.toISOString().split("T")[0];
          let max = 0;
          if (term <= 30) {
            max = 10;
          } else if (term <= 45) {
            max = 12;
          } else if (term <= 60) {
            max = 15;
          } else if (term <= 75) {
            max = 17;
          } else if (term > 75) {
            max = 20;
          }
          compareDate.setDate(compareDate.getDate() - max);
          var today = new Date();
          // this.disabledSumbit = false;
          // if (compareDate > today) {
          //   this.errorDueDate = "";
          //   this.disabledSumbit = false;
          // } else {
          //   this.errorDueDate = this.$t("due_date_issue_text");
          //   this.disabledSumbit = true;
          // }
        }
      },
      triggerFileInput() {
        this.$refs.fileInput1.$refs.input.click();
      },
    },
  };
</script>